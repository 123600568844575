<template>
  <div class="p-root">
    <img :src="banner" class="full-width" />

    <div class="full-width column-center" style="padding: 50px 0">
      <span class="intro-title">Ｐréface</span>
      <span class="intro-title">前 言</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red" style="font-size: 22px; margin-top: 30px">一场艺术、文学与儿童的国际对话</span>
      <span class="intro-red" style="font-size: 22px">一场跨越时空、国界、年龄、身份的心灵碰撞</span>

      <span class="intro-content-width intro-18"
        style="margin-top: 20px">2021年，正值20世纪世界文学史上最伟大的小说家，马赛尔·普鲁斯特诞辰150周年之际，在作家的故乡贡布雷小镇正好开放了斯万城堡，也解开了作家在他长篇巨著《追忆似水年华》中所留给后人的世纪之谜，一座惊艳世人的普鲁斯特斯万城堡，和当代艺术家婧jingParis创作的世界上第一座《爱的红教堂》，这也正是作家儿时经历情窦初开、大自然风情和一切初始创作情愫的源泉之地。</span>
      <span class="intro-content-width intro-18"
        style="margin-top: 20px">这一切引起无数文学爱好者乃至全社会的极大兴趣。法国总统马克龙也匆匆赶来，送上一张40万欧元的支票，和热忱的祝词：“在这里，文学占领了各个空间，并驻入我们的人生。”于是，各大媒体争相报道，众多民众也前来一探究竟。</span>

      <span class="intro-black intro-content-width intro-18" style="margin-top: 30px">国际成功艺术家与世界儿童联合创作</span>
      <span class="intro-content-width intro-18"
        style="margin-top: 20px">2021年9月18日欧洲遗产日，五位国际艺术大师（画家）赶来，以美丽的斯万城堡为原型，以作家儿时的回忆为元素，创作五幅“未完成”作品。而这五幅未完成的画作，将带着五位艺术家的祝福与期待，飞往中国及世界各国，与那里的孩子们开启一场神秘而有趣的对话!</span>
      <span class="intro-content-width intro-18"
        style="margin-top: 20px">这也正是本次艺术联创的奥妙所在，让孩子们在游戏中发挥各自的烂漫想象力和天真质朴的艺术才华。原来，艺术家们的每幅（未完成）作品都隐藏有各自留下的“空白”，需要孩子们寻找并填补完成。每位儿童需要揣摩、解读艺术家的巧思，同时也大胆加入自己天马行空的想法，完成五幅画作。当然，整个过程中艺术家们也会远程发来视频提示和诠释，指导各位孩子们完成创作。最终，中外艺术家组成的评审团将会选出最能与他们产生共鸣的1000幅作品，在法国艺术圣地卢浮宫卡鲁塞尔厅和斯万家的城堡，举办纪念马赛尔·普鲁斯特逝世100周年(1922—2022)的主题画展，公开展示这1000幅孩子们的画作，同时他们也将公开自己最终版的“完整画作”。</span>
      <span class="intro-content-width intro-18" style="margin-top: 20px">
        <span
          class="intro-black">毕加索说，每个孩子都是天生的画家。</span>当这些“天生的艺术家”遇到了已经成功的艺术家，东方与西方，大师与孩子们隔空的心灵交流，会产生怎样神奇的令人惊喜的火花，以及伟大的作品?</span>
    </div>

    <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 0">
      <span class="intro-title">Participants</span>
      <span class="intro-title">报名者年龄</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px">3岁-14岁儿童</span>
      <span class="intro-black intro-18" style="margin-top: 10px">按3-5岁、6-7岁、8-9岁、10-12岁、13-14岁分为5个年龄组别</span>
    </div>

    <div class="full-width column-center" style="background-color: #e0cfb8; padding: 50px 320px">
      <span class="intro-title">Thème</span>
      <span class="intro-title">巡展主题</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />
      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px">追忆似水年华·去斯万家那边</span>
    </div>

    <div class="full-width column-center" style="background-color: #072736; padding: 50px; color: white">
      <span class="intro-title">Timing</span>
      <span class="intro-title">巡展日程</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-18" style="margin-top:30px">2023年暑期活动日程：</span>

      <span class="intro-18" style="margin-top: 15px">征稿时间：</span>
      <span class="intro-red intro-18" style="font-weight: bold">2022年7月-2023年6月10日</span>

      <!-- <span class="intro-18" style="margin-top: 30px">公布获奖名单时间：</span>
      <span class="intro-18">2022年11月</span> -->

      <!-- <span class="intro-18" style="margin-top: 30px">公布复审结果（金奖/社会注目奖）时间</span>
      <span class="intro-18">2022年1月18日</span>
      <span class="intro-18">公布形式：官网和公众号公布名单</span> -->

      <span class="intro-18" style="margin-top: 30px">法国巡展时间：</span>
      <span class="intro-18">2023年7月</span>
    </div>

    <div class="full-width column-center" style="padding: 50px 320px">
      <span class="intro-title">L ’unité</span>
      <span class="intro-title">单元设置</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red intro-18" style="font-weight: bold; font-size: 18px; margin-top: 30px">主竞单元</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">·
        5位国际艺术家，分别创作一幅“未完成”的作品。参加者可在官网或公众号“联创艺术家”栏目看到清晰图。报名者完成填表和支付费用后，主办方将提供用email形式或联系报名者给予国际艺术家“未完成”作品的高清电子版，报名者可自选一幅作品下载并创作。</span>
      <span class="intro-content-width intro-18">·
        报名者按年龄选择对应“未完成”作品，继续创作。这一创作可以是在原大作品尺寸创作，也可以将“未完成”作品放大或缩小，在其中间或周围围绕完成，但创作必须是3-14岁报名者自己独立完成的。</span>
      <span class="intro-content-width intro-18">·
        5位艺术家将继续完成该“未完成”作品，完成作品将在法国卢浮宫卡鲁塞尔厅画展时与参展者的作品一同展出。</span>
      <span style="text-align: right; text-decoration: underline; cursor: pointer" class="intro-content-width"
        @click="goArtists">点击查看如何创作</span>

      <span class="intro-blue intro-18" style="font-weight: bold; font-size: 18px">小莫泊桑创造力单元</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">·
        “诺芒底1905”品牌系列之“小莫泊桑儿童奶”作为本次首席战略合作伙伴将特别设立“小莫泊桑创造力奖”。</span>
      <span class="intro-content-width intro-18">· 本单元以小莫泊桑儿童牛奶品牌基础元素为创作模版,孩子们可在模版留白区域进行自由创作,
        勇敢探索法国诺芒底青草海滨牧场……用丰富的想象力创造美好的“小莫泊桑”。</span>
      <span class="intro-content-width" style="text-align: right; text-decoration: underline; cursor: pointer"
        @click="goMilk">点击查看“小莫泊桑牛奶”</span>
    </div>

    <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 320px">
      <span class="intro-title">Prix</span>
      <span class="intro-title">奖项荣誉</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-content-width intro-red" style="
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          margin-top: 30px;
        ">【主竞单元】初审通过可获得“小莫泊桑优秀奖”</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· 数量：90%以上作品</span>
        <span class="intro-content-width">·
          选拔条件：由报名机构（在官网和公众号直接报名的作品由组委会）自行选拔，将不符合巡展要求的作品筛选出去，通过的作品和资料
          上交组委会进入复选阶段。</span>
        <span class="intro-content-width">· 入选“小莫泊桑优秀奖”可获得：</span>
        <span class="intro-content-width">
          1）国际艺术家电子签名的 《“小莫泊桑优秀奖”证书》</span>
        <span class="intro-content-width">
          2）进入组委会复审选拔，有机会获得“社会注目奖”和“小莫泊桑金奖”</span>
        <span class="intro-content-width"> 3）在官网“线上展厅”展览</span>
      </div>
      <span class="intro-content-width intro-red" style="
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          margin-top: 30px;
        ">【主竞单元】“线上展厅”展出的作品均有机会获得“社会注目奖”</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· 数量：不限</span>
        <span class="intro-content-width">·
          选拔条件：组委会随时收到作品即上传至“官网——线上展厅”，2022年10月将陆续邀请有成就的“国际国内社会各界人士”，
          在复审作品中选出心仪的作品，并撰写“推荐评语”。</span>
        <span class="intro-content-width">· 入选“社会注目奖”可获得：</span>
        <span class="intro-content-width"> 1）作品到法国巡展资格</span>
        <span class="intro-content-width">
          2）带有“有成就社会人士”推荐评语的 《“社会注目奖”作品证书》</span>
      </div>
      <span class="intro-content-width intro-red" style="
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          margin-top: 30px;
        ">【主竞单元】复审通过可获得“小莫泊桑金奖”</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· 数量：参展作品的50%</span>
        <span class="intro-content-width">· 选拔条件：组委会将作品上传至“官网”，邀请
          “中法艺术家组成的评审团”在复审作品中选出金奖作品</span>
        <span class="intro-content-width">· 入选“小莫泊桑金奖”可获得：</span>
        <span class="intro-content-width"> 1）作品到法国巡展资格</span>
        <span class="intro-content-width"> 2）国际艺术家电子签名的《“小莫泊桑金奖”证书》</span>
        <span
          class="intro-content-width">注：获得巡展资格的“社会注目奖”作品、“小莫泊桑金奖”作品可以选择是否到法国巡展，如选择参展需支付国际布展费1380元人民币/作品，参与巡展作品获得以下奖励：</span>
        <span class="intro-content-width">
          1）法中交流促进会出具的《卢浮宫卡鲁塞尔厅参展证明信》</span>
        <span class="intro-content-width">
          2）组委会提供作品在法国巡展的图文电子版资料</span>
        <span class="intro-content-width">
          3）作品编入《国际艺术联创巡展》画册（画册需单独购买，200元/套）</span>
        <span class="intro-content-width">
          4）作品及作者信息有机会出现在中外媒体报道中</span>
        <span class="intro-content-width">
          5）有机会获得“卢浮宫卡鲁塞尔厅”展览现场选出的“特别大奖”</span>
        <span class="intro-content-width">
          6）可获得国际艺术联创“小莫泊桑”奖牌</span>
      </div>
      <span class="intro-content-width intro-blue"
        style="font-size: 22px;font-weight: bold;text-align: center;margin-top: 30px;">【创造力单元】“小莫泊桑创造力奖”</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· 数量：100名</span>
        <span class="intro-content-width">· 选拔条件：由“诺芒底1905”品牌选出</span>
        <span class="intro-content-width">· 入选“小莫泊桑创造力奖”可获得：</span>
        <span class="intro-content-width"> 1）“小莫泊桑创造力奖证书”</span>
        <span class="intro-content-width">
          2）作品编入《国际艺术联创巡展》画册（画册需单独购买，200元/套）</span>
        <span class="intro-content-width"> 3）作品到法国巡展资格</span>
        <span class="intro-content-width">
          4）“诺芒底1905”品牌颁发的“小莫泊桑创造力宣传大使”称号</span>
      </div>
      <span class="intro-black" style="font-size: 22px; font-weight: bold; margin-top: 30px">法国巡展</span>
      <div class="column intro-18" style="margin-top: 10px">
        <span class="intro-content-width">· 时 间：2023年7月</span>
        <span class="intro-content-width">· 地 点：巴黎卢浮宫卡鲁赛尔厅&马赛尔•普鲁斯特 “斯万城堡”</span>
        <span class="intro-content-width">· 说
          明：获得“社会注目奖作品”、“小莫泊桑金奖作品”、“小莫泊桑创造力奖作品”都有参与法国巡展的权利，如选择参展，需另行缴纳“国际布展费1380元人民币/作品。在法国巡展上，5位艺术家也将同时公布自己的“完整画作”并一同展览。</span>
      </div>
    </div>

    <div class="full-width column-center" style="background-color: #e0cfb8; padding: 50px 320px">
      <span class="intro-title">Frais</span>
      <span class="intro-title">费用说明</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px">资料费（报名时支付）</span>
      <span class="intro-red" style="font-size: 20px; margin-top: 10px">300元人民币/作品</span>
      <span class="intro-content-width intro-18"
        style="margin-top: 10px">报名时需填写报名表，并支付本项资料费，资料费包含：手续费、翻译费、国际联络费、评审费、运费等</span>
      <span class="intro-content-width">注：参加者可以同时参加【主竞单元】和【创造力单元】两个单元，1张作品对应1份资料费</span>

      <span class="intro-red" style="font-size: 22px; font-weight: bold; margin-top: 30px">国际布展费（通过复审后支付）</span>
      <span class="intro-red" style="font-size: 20px; margin-top: 10px">1380元人民币/作品</span>
      <span class="intro-content-width intro-18" style="margin-top: 10px">复审公布结果后，获得“社会注目奖作品”和“小莫泊桑金奖作品”、“小莫泊桑创造力奖作品”，
        都可选择是否参加在法国卢浮宫卡鲁塞尔厅、斯万城堡举办的巡展，选择参展的作品需支付本项费用</span>
    </div>

    <div class="full-width column-center" style="padding: 50px 320px">
      <span class="intro-title">Règle</span>
      <span class="intro-title">参加须知</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 30px">
        <div class="intro-black" style="font-weight: bold">·详细流程：</div>
        <div style="flex: 1">
          报名者通过“官网”或“公众号”或“线下合作机构”报名后，获得“国际画家未完成作品高清图”，报名者进行创作。
          完成创作后，将原作快递至组委会（地址见本网站“联系我们”），递交作品时无需装框，作品背面需附A4报名表（手填
          或打印皆可，手写需正楷书写），以便组委会确认画作作者及报名信息
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·作品类型：</div>
        <div style="flex: 1">
          水墨画、水彩画、水粉画、油画、蜡笔画、素描、卡通画、漫画、彩色水笔、版画、拼贴画、钢笔画、综合绘画等类型作品
          皆可参展。（注：计算机数字绘画等电子版作品不能参展）
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·作品规格：</div>
        <div style="flex: 1">
          厚纸张，大小为A3（29.7cm*42cm）或8开（26.5cm*38cm）。不符合尺寸的作品，视为无效作品。
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·作品数量：</div>
        <div style="flex: 1">
          参展者可同时参与【主竞单元】、【创造力单元】两个单元，每幅作品对应1份报名表+资料费。如两幅作品都获得巡展资
          格需支付两份国际布展费。参展者每个单元仅限1幅作品。
        </div>
      </div>
      <div class="row intro-content-width intro-18" style="align-items: flex-start; margin-top: 10px">
        <div class="intro-black" style="font-weight: bold">·作品要求：</div>
        <div style="flex: 1">
          参与【主竞单元】的报名者任选一幅“国际画家未完成作品”，进行继续创作，参展作品仅限本人创作，合作和协作作品不能参展，容易损坏、凹凸不平的造型作品不能参展。不能使用容易生虫的谷物类及可能腐烂的或易碎易折损的创作材料。
        </div>
      </div>
    </div>

    <div class="full-width column-center" style="background-color: #f5efe6; padding: 50px 320px">
      <span class="intro-title">Inscription</span>
      <span class="intro-title">报名方式</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <div class="row intro-content-width" style="margin-top: 30px; align-items: flex-start">
        <div class="column" style="width: 400px">
          <span class="intro-red" style="font-size: 22px; font-weight: bold">方式1：直接在官网报名</span>
          <span class="intro-18">·在本官网点击“我要报名”进行报名</span>
          <span class="intro-18">·也可以关注公众号，在公众号报名，同时可获得大赛实时信息通知</span>
        </div>
        <div class="column" style="width: 400px">
          <span class="intro-red" style="font-size: 22px; font-weight: bold">方式2：在线下合作机构报名</span>
          <span class="intro-18">·报名者可在本次活动的线下合作机构报名</span>
          <span class="intro-18">·由各报名点汇总报名信息，统一上报组委会</span>
        </div>
      </div>
      <div class="row intro-content-width" style="align-items: flex-start; margin-top: 30px">
        <img :src="icons.footer_qr_code" style="width: 160px" />
        <span class="intro-18" style="margin-top: 30px; margin-left: 80px">注：本次活动参展报名方式设以上2种，报名者任选其一即可，无需重复报名</span>
      </div>
    </div>

    <div class="full-width" style="background-color: #2588ce; height: 20px"></div>

    <div class="full-width column-center" style="background-color: #072736; padding: 50px; color: white">
      <span class="intro-title">Droits</span>
      <span class="intro-title">作品权益声明</span>
      <img :src="icons.triangle" height="13" style="margin-top: 10px" />

      <div class="row intro-content-width" style="justify-content:center;font-size: 24px; margin-top: 30px">
        第一届“小莫泊桑杯”国际艺术联创巡展</div>
      <div class="row intro-content-width" style="justify-content:center;font-size: 24px">参展作品知识产权及相关声明</div>

      <div class="row intro-18 intro-content-width" style="margin-top: 40px;">
        一. 第一届“小莫泊桑杯”国际艺术联创巡展（以下简称“联创活动”）依照《中华人民共和国著作权法》、《中华人民共和国著作权法实施条例》及其他相关法律规定开展著作权保护工作。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        二. 参展者一旦报名成功，并支付资料费，如因参展者个人原因不能参加联创活动的，资料费概不退还。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        三. 参展者须取得其监护人的同意，参展者或其监护人已知悉、确认并同意严格遵守本次联创活动征稿启事的各项规则（含参展作品知识产权及相关声明，以下简称“本声明”）。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        四. 参展者所提交的参展作品应由本人独立创作完成，为原创作品。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        五. 参展者提交的一切图文资料内容信息应真实有效，不构成对第三方知识产权、肖像权、隐私权、名誉权或者其他合法权益的侵害，否则将取消其参展资格，相关法律后果由参展者自行承担。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        六. 未经活动主办方同意，任何第三方不得以任何形式使用参展者及参展作品的任何信息、图像等，本约束条件不因联创活动结束而终止。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        七. 参展期间，参展者不得转让参展作品，不得许可第三方使用。在未取得联创活动主办方书面同意的情况下，不得将参展作品用于商业用途。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        八. 如因参展者的疏忽、过失、故意而发生任何侵犯版权或其他知识产权的行为，并因此给活动主办方造成任何损失和损害，参展者须负责并承担一切责任。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        九. 参展作品所有权归属法中交流促进会（Association pour le Développement des échanges
        France-Chinois）（以下简称“法中交流促进会”）或其指定方所有，参展作品一律不予退回。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        十．如果参展作品参与商业拍卖或者出售，则按照参与该参展作品的联创活动特约艺术家（Malgorzata Paszko/ Christophe Ronel/Emmanuelle Renard/Sophie
        Sainrapt/Augusto Foldi之一）3%、参与该参展作品的参展者8%的比例分享参展作品拍卖或者出售所得款项；如果参展作品参与公益拍卖，公益拍卖所得款项将全部捐赠给相关公益项目。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        十一.
        参展者除享有参展作品署名权之外，参展作品的其他著作权及领接权，包括但不限于参展作品发表权、修改权、保护作品完整权、复制权、发行权、展览权、表演权、放映权、信息网络传播权、摄制权、改编权、翻译权、汇编权归法中交流促进会或其指定方所有。参展作品的衍生品、基于参展作品及其衍生品生成或创作的NFT作品的完整著作权及领接权（包括但不限于署名权、发表权、修改权、保护作品完整权、复制权、发行权、展览权、表演权、放映权、信息网络传播权、摄制权、改编权、翻译权、汇编权）亦归法中交流促进会或其指定方所有。在参展作品参展期间及参展之后，法中交流促进会有权在处理参展作品相关的活动中免费使用参展者的姓名及肖像。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        十二.
        在联创活动过程中有下列侵权行为的，侵权人应当根据情况，承担停止侵害、消除影响、赔礼道歉、赔偿损失等民事责任；同时损害公共利益的，可以由著作权行政管理部门责令停止侵权行为，没收违法所得，没收、销毁侵权复制品，并可处以罚款；情节严重构成犯罪的，依法追究其法律责任：
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        1. 未经著作权人许可，将其作品用于参展的；
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        2. 未经其他作者许可将他人的作品当作自己的作品参展的；
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        3. 歪曲、篡改或剽窃他人作品的；
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;; margin-left: 30px">
        4. 其他侵犯著作权以及与著作权有关的权益的行为。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        十三. 如发生任何非人为因素或其他超出联创活动主办方控制能力以外的意外事件，包括地震、火灾、水灾、骚乱、战争、罢工、时疫、政府法令的变更等不可抗力事件，联创活动主办方有权取消、延期或相应调整联创活动以及有关具体安排。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        十四.
        参展者及其监护人在签署本声明时，已经认真阅读本声明的内容，并确认签署本声明是参展者及其监护人真实意思表示。一旦参加联创活动的任何一个环节或全部，即视为参展者及其监护人已完全理解并完全接受包括本声明在内的各项条款，并愿意承担接受本声明所产生的一切法律后果。本声明是具有法律效力的文件，本声明涉及的各方可以按照本声明确定参展者所提交的参展作品的相关法律权益。
      </div>
      <div class="intro-18 intro-content-width" style="margin-top: 20px;">
        十五. 法中交流促进会或其指定方保留对联创活动的各项规则最终解释权。
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as eventHelper from "../../utils/eventHelper";
export default {
  data() {
    return {
      icons: this.$root.icons,

      banner: "",
    };
  },
  mounted() {
    setTimeout(() => {
      eventHelper.triggerEvent("footer-change-background", {
        backgroundColor: "#062C3E",
        fontColor: "#ffffff",
      });
    }, 500);

    this.getBanner();
  },
  methods: {
    ...mapActions({
      positionGetContentAction: "positionGetContent",
    }),
    goArtists() {
      // this.$router.push({
      //   name: "artists",
      // });
      this.$router.push({
        name: "create",
      });
    },
    goMilk() {
      window.open(
        "https://shop92949538.m.youzan.com/v2/showcase/homepage?alias=eC6Gfn4IzM&dc_ps=2841760543432437765.300001"
      );
    },
    getBanner() {
      this.positionGetContentAction({
        position_id: 6,
      }).then((res) => {
        this.banner = res.img_url;
      });
    },
  },
};
</script>

<style scoped>
.intro-content-width {
  width: 800px;
}
.divide {
  background-color: #ffffff;
  height: 1px;
  width: 500px;
  opacity: 0.5;
}
.intro-title {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  letter-spacing: 0;
  text-align: center;
}
.intro-title-white {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white {
  font-family: PingFangSC-Medium;
  font-size: 18px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 35px;
}
.intro-title-blue {
  font-family: PingFangSC-Semibold;
  font-size: 30px;
  color: #062c3e;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-white-24 {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
}
.intro-content-blue {
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #062c3e;
  letter-spacing: 0;
  line-height: 44px;
}
.intro-title-yellow {
  font-family: ZhenyanGB-Regular;
  font-size: 30px;
  color: #f7eb50;
  letter-spacing: 0;
}
.intro-btn {
  cursor: pointer;
  background: #062c3e;
  border-radius: 8px;
  padding: 0 30px;
  height: 65px;
  align-self: flex-start;
  font-family: PingFangSC-Semibold;
  font-size: 24px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 65px;
}

.intro-red {
  color: #f72e02;
}
.intro-blue {
  color: #0088cd;
}
.intro-black {
  color: #000000;
}

.intro-18 {
  font-size: 18px;
}
</style>